import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@app/interfaces/user.interface';
import { Observable } from 'rxjs';

interface AuthenticationOptions {
  redirectUser: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  authentication(
    login: string,
    password: string,
    options: AuthenticationOptions = { redirectUser: true },
  ) {
    return this.http.post<any>(`${environment.authUrl}/authenticate`, { login, password }).pipe(
      tap(({ user }) => {
        this.putCookieUser(user);

        if (options.redirectUser) {
          this.redirectUser(user);
        }
      }),
    );
  }

  resetPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/sso/forget-password`, { email });
  }

  getCurrentUser(): User {
    return this.cookieService.getObject('currentUser') as User;
  }

  connectPsc(code: string) {
    return this.http.post<any>(`${environment.authUrl}/psc/authenticate`, { code });
  }

  chooseUserPsc(id: string) {
    const { token } = this.getCurrentUser();

    return this.http.post<any>(`${environment.authUrl}/psc/authenticateUser`, { token, id }).pipe(
      tap(({ user }) => {
        this.putCookieUser(user);
        this.redirectUser(user);
      }),
    );
  }

  linkUserPsc(login: string, password: string) {
    const { token } = this.getCurrentUser();

    return this.http
      .post<any>(`${environment.authUrl}/psc/authenticateNewUser`, { login, password, token })
      .pipe(
        tap(({ user }) => {
          this.putCookieUser({ ...user, token });
          this.redirectUser(user);
        }),
      );
  }

  listUserPsc(token: string) {
    return this.http.post<any>(`${environment.authUrl}/psc/users`, { token });
  }

  putCookieUser(user: Partial<User>) {
    const expires = new Date();
    expires.setTime(expires.getTime() + 8 * 60 * 60 * 1000);
    this.cookieService.putObject('currentUser', this.getBasicUserForCookies(user), {
      domain: environment.cookieDomain,
      sameSite: 'lax',
      expires,
    });
  }

  clearCookieUser() {
    this.cookieService.remove('currentUser', {
      domain: environment.cookieDomain,
      sameSite: 'lax',
    });
  }

  getBasicUserForCookies(user) {
    const {
      _id,
      token,
      firstName,
      lastName,
      fullName,
      companyName,
      supervisorDoctor,
      type,
      adminType,
      sharedProfile,
      isPSC,
      isAnonymizer,
      language,
      biologicDataUnit,
      prescriptionTemplateAuthorization,
      prescriptionTemplate,
      authorizeBiologicData,
      enableScale,
      enableTensio,
      selectedIdJfse,
      enableJfse,
    } = user;

    return {
      _id,
      token,
      firstName,
      lastName,
      fullName,
      companyName,
      supervisorDoctor,
      type,
      adminType,
      sharedProfile,
      isPSC,
      isAnonymizer,
      language,
      biologicDataUnit,
      prescriptionTemplateAuthorization,
      prescriptionTemplate,
      authorizeBiologicData,
      enableScale: enableScale === undefined ? true : enableScale,
      enableTensio: enableTensio === undefined ? true : enableTensio,
      selectedIdJfse,
      enableJfse,
    };
  }

  redirectUser(user: User) {
    if (user.passwordExpire) {
      this.router.navigate(['/renewPassword']);
    } else if (user.type !== 'admin' && !user.cguAccepted) {
      this.router.navigate(['/cgu']);
    } else {
      const regex = new RegExp(environment.newcardURL);
      const returnUrl = this.route.snapshot.queryParams?.returnUrl;

      if (regex.test(returnUrl)) {
        document.location.href = returnUrl;
      } else {
        document.location.href = environment.psp;
      }
    }
  }

  logoutFromPSC(): Observable<void> {
    const { token } = this.getCurrentUser();

    return this.http.post<void>(`${environment.authUrl}/psc/logout`, { token });
  }

  changePassword(password, confirmPassword) {
    const { token } = this.getCurrentUser();

    return this.http.post<void>(`${environment.authUrl}/renewPassword`, {
      token,
      password,
      confirmPassword,
    });
  }
}
